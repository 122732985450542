import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import useSiteMetadata from '@zd/hooks/useSiteMetadata';
import useNavigate from '@zd/hooks/useNavigate';
import PageSEO from '@zd/components/page-seo';
import Link from '@zd/components/link';

const StyledLink = styled(Link)`
  display: inline-block;
`;

const Img = styled.img`
  width: 250px;
`;

export default props => {
  const siteMetadata = useSiteMetadata();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);

  return (
    <>
      <PageSEO {...props} />
      <StyledLink href="/" title={siteMetadata.title}>
        <Img src="/images/logo.svg" alt={siteMetadata.title} title={siteMetadata.title} />
      </StyledLink>
    </>
  );
};
